import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface ArrowButtonProps {
  direction?: 'left' | 'right';
  bgColor?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

const useStyles = makeStyles({
  button: (props: ArrowButtonProps) => ({
    backgroundColor: props.bgColor || '#f1cfcd',
    margin: '5px 0',
    padding: '5px 10px',
    position: 'relative',
    display: 'inline-block',
    cursor: 'pointer',
    height: '34px',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 0,
      height: 0,
      borderTop: '17px solid transparent',
      borderBottom: '17px solid transparent',
      ...(props.direction === 'right'
        ? {
            right: '-20px',
            borderLeft: `20px solid ${props.bgColor || '#f1cfcd'}`,
          }
        : {
            left: '-20px',
            borderRight: `20px solid ${props.bgColor || '#f1cfcd'}`,
          }),
    },
    '&:hover': {
      backgroundColor: '#e0b3b0',
      '&:after': {
        ...(props.direction === 'right'
          ? {
              borderLeftColor: '#e0b3b0',
            }
          : {
              borderRightColor: '#e0b3b0',
            }),
      },
    },
  }),
});

const ArrowButton: React.FC<ArrowButtonProps> = (props) => {
  const { direction = 'right', bgColor = '#f1cfcd', onClick, children } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.button} onClick={onClick}>
      <Typography>{children}</Typography>
    </Box>
  );
};

export default ArrowButton;
