import React, {useEffect} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography
} from '@material-ui/core';
import {Check as CheckIcon, Close as CloseIcon} from '@material-ui/icons';
import {DigiReturnsAllFoundDialog} from "../digi-returns-all-found-dialog";
import {DigiReturnsIncludeRemoveDialog} from "../digi-returns-include-remove";

interface IAllFoundDialog {
    open: boolean;
    digiItems: IDigiItem[];
    parcelItems: any[];

    setParcelItems(items: any): void;

    onCancel(): void;
    onAddManually(): void;
    onParcelClose(): void;
}

interface IDigiItem {
    id: string,
    outbound_item_found: boolean,
    parcelItems: any[];
    sku: string,
    item_description?: string
}

export const DigiReturns = (props: IAllFoundDialog) => {
  // initially show if all items are found
  const [showAllFoundDialog, setShowAllFoundDialog] = React.useState(false);
  // initially show if all not all items are found
  const [showIncludeRemoveDialog, setShowIncludeRemoveDialog] = React.useState(false);

  const [digiItems, setDigiItems] = React.useState<IDigiItem[]>([]);

  useEffect(() => {
    // sort digiItems by outbound_item_found
    setDigiItems(props.digiItems.sort((a, b) => (a.outbound_item_found === b.outbound_item_found) ? 0 : a.outbound_item_found ? -1 : 1));
    const allExist = props.digiItems.every(item => item.outbound_item_found);
    setShowAllFoundDialog(allExist);
    setShowIncludeRemoveDialog(!allExist);
  }, [props.digiItems]);

  return (
    <>
    <DigiReturnsAllFoundDialog open={showAllFoundDialog} digiItems={digiItems}
                               parcelItems={props.parcelItems}
                               setParcelItems={props.setParcelItems}
                               onConfirm={() => {}} onCheck={() => {setShowAllFoundDialog(false); setShowIncludeRemoveDialog(true);}}
                               onParcelClose={() => {setShowAllFoundDialog(false); props.onParcelClose();}}
                               onCancel={props.onCancel}/>
      <DigiReturnsIncludeRemoveDialog open={showIncludeRemoveDialog} digiItems={digiItems}
                                      parcelItems={props.parcelItems}
                                      setParcelItems={props.setParcelItems}
                                      onConfirm={() => {}}
                                      onAddManually={props.onAddManually}
                                      onParcelClose={() => {setShowIncludeRemoveDialog(false); props.onParcelClose();}}
                                      onCancel={props.onCancel}/>
    </>
  );
};
