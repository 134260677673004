import React, {useEffect} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Typography
} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import ArrowButton from "./arrow-button";
import Tooltip from "@material-ui/core/Tooltip";

interface IIncludeRemoveDialog {
  open: boolean;
  digiItems: IDigiItem[];

  parcelItems: any[];

  setParcelItems(items: any): void;

  onConfirm(): void;

  onParcelClose(): void;

  onCancel(): void;

  onAddManually(): void;
}

interface IDigiItem {
  id: string,
  outbound_item_found: boolean,
  sku: string,
  item_description?: string
}


export const DigiReturnsIncludeRemoveDialog = (props: IIncludeRemoveDialog) => {
  // if all digiItems.outbound_item_found === true the backGroundColor will be green, otherwise red
  const colorGreen = '#edf5d1';
  const colorRed = '#fed5d5';

  const [includedItems, setIncludedItems] = React.useState<IDigiItem[]>([]);
  const [removedItems, setRemovedItems] = React.useState<IDigiItem[]>([]);

  useEffect(() => {
    setIncludedItems(props.digiItems.filter(item => item.outbound_item_found));
    setRemovedItems(props.digiItems.filter(item => !item.outbound_item_found));

    // set items from props, add item as {EWS: item.id}
    props.setParcelItems(props.digiItems.filter(item => item.outbound_item_found).map(item => ({EWS: item.id, QPL: item.sku})));
  }, [props.digiItems]);

  const includeItem = (item: IDigiItem) => {
    setIncludedItems([...includedItems, item]);
    setRemovedItems(removedItems.filter(i => i !== item));

    // add item to items from props
    props.setParcelItems((prevItems: any) => {
      if (!prevItems.some((prevItem: any) => prevItem.EWS === item.id)) {
        return [...prevItems, {EWS: item.id, QPL: item.sku}];
      }
      return prevItems;
    });
  }

  const removeItem = (item: IDigiItem) => {
    setRemovedItems([...removedItems, item]);
    setIncludedItems(includedItems.filter(i => i !== item));
    // remove item from items from props
    props.setParcelItems((prevItems: any) => {
      return prevItems.filter((prevItem: any) => prevItem.EWS !== item.id);
    });
  }

  const renderIncludeListParcelItem = (item: IDigiItem) => {
    if (!includedItems.includes(item)) {
      return <Box sx={{width: "100%", height: "40px"}}/>
    }

    return (
      <Box style={{width: "100%", height: 40}}>
        <Typography style={{
          marginLeft: 10,
          width: "90%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis"
        }}><strong>Item
          - {item.sku} </strong> - {item.outbound_item_found ? (
          <span style={{color: 'green'}}>ITEM FOUND</span>
        ) : (
          <span style={{color: 'red'}}>ITEM NOT FOUND</span>
        )} - {item.item_description}
        </Typography>
      </Box>
    );
  }

  const renderRemoveListParcelItem = (item: IDigiItem) => {
    if (!removedItems.includes(item)) {
      return <Box sx={{width: "100%", height: "40px"}}/>
    }

    return (
      <Box style={{width: "100%", height: 40}}>
        <Typography style={{
          marginLeft: 10,
          width: "90%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis"
        }}><strong>Item
          - {item.sku} </strong> - {item.outbound_item_found ? (
          <><span style={{color: 'green'}}>ITEM FOUND </span> - {item.item_description}</>
        ) : (
          <span style={{color: 'red'}}>ITEM NOT FOUND</span>
        )}
        </Typography>
      </Box>

    );
  }

  const renderContent = () => {
    return (
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {/* Include List */}
            <Grid container>
              <Grid item xs={10}>
                <Typography style={{fontWeight: "bold"}}>Include</Typography>
                <List style={{backgroundColor: colorGreen}}>
                  {props.digiItems.map((item, index) => (
                    renderIncludeListParcelItem(item)
                  ))}
                </List>
              </Grid>
              <Grid item xs={2}>
                <Box justifyContent="flex-end" style={{
                  marginTop: 22,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}>
                  {props.digiItems.map((item, index) => (
                    <Box style={{marginLeft: 10, height: "40px"}}>
                      {/* if it's in included items, show Remove button */}
                      {includedItems.includes(item) ? (
                        <ArrowButton direction="right" bgColor="#f1cfcd"
                                     onClick={() => removeItem(item)}>
                          <Typography>Remove</Typography>
                        </ArrowButton>
                      ) : (
                        <Box/>
                      )}
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {/* Remove List */}
            <Grid container>
              <Grid item xs={2}>
                <Box justifyContent="flex-end" style={{
                  marginTop: 22,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end'
                }}>
                  {props.digiItems.map((item, index) => (
                    <Box style={{marginRight: 10, height: "40px"}}>
                      {item.outbound_item_found && removedItems.includes(item) ? (
                        <ArrowButton direction="left" bgColor="#d8e8d6"
                                     onClick={() => includeItem(item)}>
                          <Typography>Include</Typography>
                        </ArrowButton>
                      ) : (
                        <Box/>
                      )}
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Typography style={{fontWeight: "bold"}}>Remove</Typography>
                <List style={{backgroundColor: colorRed}}>
                  {props.digiItems.map((item, index) => (
                    renderRemoveListParcelItem(item)
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Typography style={{marginTop: 20, fontWeight: "bold"}}>Include or remove items from the
          parcel.</Typography>
      </Box>
    );
  }
  return (
    <Dialog open={props.open} disableBackdropClick fullWidth maxWidth="xl">
      <DialogTitle>
        Digital Parcel
      </DialogTitle>
      <DialogContent>
        {renderContent()}

        {/* Used for debug - uncomment to show items in the parcel */}
        {/*
        {props.parcelItems.length > 0 && (
          <Typography style={{marginTop: 20, fontWeight: "bold"}}>Items in the
            parcel:</Typography>
        )}
        {props.parcelItems.map((item, index) => (
          <Typography key={index} style={{marginLeft: 20}}>{item.EWS}</Typography>
        ))}
        */}

      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={props.onCancel} color="default">
          Cancel
        </Button>
        <Tooltip title="Items under the 'Include' list will be added in addition to the ones added manually" placement="top" arrow={true}>
          <Button variant="contained" onClick={props.onAddManually} color="primary">
            Add Items Manually
          </Button>
        </Tooltip>
        <Button variant="contained" onClick={props.onParcelClose} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
};
