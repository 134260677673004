import React, {useEffect} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Typography
} from '@material-ui/core';

interface IAllFoundDialog {
  open: boolean;
  digiItems: IDigiItem[];
  parcelItems: any[];

  setParcelItems(items: any): void;

  onConfirm(): void;

  onCheck(): void;

  onParcelClose(): void;

  onCancel(): void;
}

interface IDigiItem {
  id: string,
  outbound_item_found: boolean,
  sku: string,
  item_description?: string
}

export const DigiReturnsAllFoundDialog = (props: IAllFoundDialog) => {
  const colorGreen = '#edf5d1';

  useEffect(() => {
    // add all digiItems to the parcelItems
    props.digiItems.forEach((item) => {
      if (item.outbound_item_found) {
        props.setParcelItems((prevItems: any) => {
          if (!prevItems.some((prevItem: any) => prevItem.EWS === item.id)) {
            return [...prevItems, {EWS: item.id, QPL: item.sku}];
          }
          return prevItems;
        });
      }
    });
  }, [props.digiItems]);


  const renderParcelItem = (item: IDigiItem) => {
    return (
      <Box style={{width: "100%", height: 40}}>
        <Typography style={{
          marginLeft: 10,
          width: "90%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis"
        }}><strong>Item
          - {item.sku} </strong> - {item.outbound_item_found ? (
          <span style={{color: 'green'}}>ITEM FOUND</span>
        ) : (
          <span style={{color: 'red'}}>ITEM NOT FOUND</span>
        )} - {item.item_description}
        </Typography>
      </Box>
    );
  }


  const renderContentAllExist = () => {
    return (
      <Box>
        <List style={{
          marginTop: 30, marginBottom: 30, backgroundColor: colorGreen
        }}>
          {props.digiItems.map((item, index) => (
            renderParcelItem(item)
          ))}

        </List>
        <Typography style={{marginTop: 20, fontWeight: "bold"}}>All items have been
          found!</Typography>
        <Typography style={{fontWeight: "bold"}}>
          Scan the
          return parcel to seal it.</Typography>
      </Box>
    );
  }

  return (
    <Dialog open={props.open} disableBackdropClick fullWidth maxWidth="md">
      <DialogTitle>
        Digital Parcel
      </DialogTitle>
      <DialogContent>
        {renderContentAllExist()}
        {/* Used for debug - uncomment below to show items in the parcel */}
        {/*
        {props.parcelItems.length > 0 && (
          <Typography style={{marginTop: 20, fontWeight: "bold"}}>Items in the
            parcel:</Typography>
        )}
        {props.parcelItems.map((item, index) => (
          <Typography key={index} style={{marginLeft: 20}}>{item.EWS}</Typography>
        ))}
        */}

      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={props.onCancel} color="default">
          Cancel
        </Button>
        <Button variant="contained" onClick={props.onCheck} color="primary">
          Check
        </Button>
        <Button variant="contained" onClick={props.onParcelClose} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>

  )
};
