import React, {RefObject, useEffect, useState} from 'react';
import {createStyles, makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import {Button, Grid, TextField, Theme, Typography} from '@material-ui/core';
import {Check as CheckIcon} from '@material-ui/icons';
import {StatusPopup} from '../../../common/status-popup';
import {formatAlphanumericInput} from '../../../../helpers';
import {getParcel} from "../../../../services/api";
import {customTheme} from "../../../main/materialStyles";
import {yodelBarcodePrefix} from "../../../../constants";
import {DigiReturns} from "../digi-returns";

interface IScanParcelLabelProps {
  enteringFinished: boolean;
  isParcelClosingInProgress: boolean;
  parcelItems: any;
  upi: string;
  parcelLabelInputRef?: RefObject<HTMLInputElement>;

  onParcelLabelInputComplete(upi: string): void;

  setParcelItems(items: any): void;

  onUPIChange(upi: string): void;

  onParcelClose(): void;
  onParcelCancel(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3, 0)
    },
    statusContainer: {
      background: '#edf5d1',
      color: '#528618',
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0, 2),
      margin: theme.spacing(0, 0, 0, 2),
      alignItems: 'center',
      justify: 'space-between'
    },
    parcelStatusActionsContainer: {
      textAlign: 'right'
    },
    statusIcon: {
      float: 'left'
    }
  })
);

export const ScanParcelLabel = (props: IScanParcelLabelProps) => {
  const classes = useStyles();
  const states = {
    NEW: 'new',
    ERRORED: 'errored',
    SUCCEDED: 'succeded',
    DIGI: 'digi',
    INVALID: 'invalid',
  };

  const {
    enteringFinished,
    parcelLabelInputRef,
    isParcelClosingInProgress,
    onParcelLabelInputComplete,
    onUPIChange,
    onParcelCancel
  } = props;
  const [upi, setUPI] = useState('');
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showDigiPopup, setShowDigiPopup] = useState(false);
  const [upiStatus, setUpiStatus] = useState(states.NEW);

  const [digiItems, setDigiItems] = useState([]);

  useEffect(() => {
    setShowSuccessPopup(enteringFinished);
  }, [enteringFinished]);

  const handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.which === 13) {
      event.preventDefault();
      try {
        if (upi.startsWith(yodelBarcodePrefix)) {
          // Reject the scan if user has scanned the Yodel routing barcode
          setUpiStatus(states.INVALID);
          onUPIChange('');
        }  else {
          const result: any = await getParcel(upi);

          // if a digi return
          if (result.is_digi_return) {
            setShowDigiPopup(true);
            setUpiStatus(states.SUCCEDED);
            onParcelLabelInputComplete(upi);
            setDigiItems(result.items);
          } else {
            // if parcel already exists, and not a digi return, show error message
            setUpiStatus(states.ERRORED);
            onUPIChange('');
          }
        }
      } catch (error) {
        // if parcel does not exist, show success message
        setUpiStatus(states.SUCCEDED);
        onParcelLabelInputComplete(upi);
      }
    }
  };

  const handleInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const element = event.target as HTMLInputElement;
    setUpiStatus(states.NEW);
    setUPI(formatAlphanumericInput(element.value));
    onUPIChange(formatAlphanumericInput(element.value));
  };

  const ParcelStatus = () => (
    <Grid item xs container className={classes.statusContainer}>
      <Grid item xs>
        <Typography variant="subtitle1">
          <CheckIcon className={classes.statusIcon}/>
          Parcel <strong>{upi}</strong> has been created
        </Typography>
      </Grid>
      <Grid item xs className={classes.parcelStatusActionsContainer}>
        <Button variant="contained" onClick={onParcelCancel} disabled={isParcelClosingInProgress}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <form className={classes.root}>
      <MuiThemeProvider theme={customTheme}>
        <Grid container>
          <Grid item xs={4}>
            <TextField
              fullWidth
              autoFocus
              inputProps={{
                maxLength: 25
              }}
              autoComplete="off"
              disabled={enteringFinished}
              onKeyPress={handleKeyPress}
              onInput={handleInput}
              label="Returns Label"
              variant="outlined"
              name="upi"
              value={props.upi}
              inputRef={parcelLabelInputRef}
            />
          </Grid>
          {showSuccessPopup && upiStatus === states.SUCCEDED &&
            <StatusPopup text="Parcel has been created" type="SUCCESS" upi={upi}/>}
          {showDigiPopup && upiStatus === states.SUCCEDED &&
            <DigiReturns
              open={showDigiPopup}
              digiItems={digiItems}
              parcelItems={props.parcelItems}
              setParcelItems={props.setParcelItems}
              onAddManually={() => {
                setShowDigiPopup(false)
              }}
              onParcelClose={() => {
                props.onParcelClose();
                setShowDigiPopup(false);
              }}
              onCancel={() => {
                onParcelCancel();
                setShowDigiPopup(false);
              }}
              />}
          {!showSuccessPopup && upiStatus === states.ERRORED &&
            <StatusPopup text="Parcel already exist" type="ERROR" upi={upi}/>}
          {!showSuccessPopup && upiStatus === states.INVALID &&
            <StatusPopup text="Invalid Barcode Scanned" type="INVALID" upi={upi}
                         key={Math.random()}/>}
          {enteringFinished && <ParcelStatus/>}
        </Grid>
      </MuiThemeProvider>
    </form>
  );
};
